export const getPage = state => state.insights;

export const getGroupId = state => state.global.groupList.selectedGroup;

export const getLoading = state => state.insights.loading;

export const getError = state => state.insights.error;

export const getReportingDateRange = state => state.insights.date;

export const getFirstAttemptPerformance = state => state.insights.first;

export const getFirstAttemptPerformanceIsLoading = state => state.insights.first.isLoading;

export const getConnectionsByAttempt = state => state.insights.connections;

export const getConnectionsByAttemptIsLoading = state => state.insights.connections.isLoading;

export const getNoConnections = state => state.insights.disconnected;

export const getNoConnectionsIsLoading = state => state.insights.disconnected.isLoading;

export const getLeadCallVolume = state => state.insights.volumes;

export const getLeadCallVolumeIsLoading = state => state.insights.volumes.isLoading;

export const getNewLeadFlow = state => state.insights.flow;

export const getNewLeadFlowIsLoading = state => state.insights.flow.isLoading;

export const getHighestConnections = state => state.insights.highest;

export const getHighestConnectionsIsLoading = state => state.insights.highest.isLoading;

export const getGroupsLoading = state => state.groups.loading;

export const getGroups = state => state.groups.list;
