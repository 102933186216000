/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import { Line } from 'react-chartjs-2';
import { makeStyles, Typography, Button, Link } from '@material-ui/core';
import { format, parse } from 'date-fns';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from 'src/components/table';
import Tab from 'src/components/tab';
import ChartLegend from 'src/components/chartLegend';
import ChartWrapper from 'src/components/chartWrapper';
import TooltipedText from 'src/components/tooltipedText';
import Notice from 'src/components/notice';
import RichText from 'src/components/richText';
import HelpWidget from 'src/components/helpWidget';
import OpenMoc from 'src/features/openPrc';
import { coloredDatasets } from 'src/helpers/chartJsHelpers';
import { getWeekDayName } from 'src/helpers/time';

import pages from 'src/features/me/pages';
import { getMe } from 'src/features/users/state/selectors';

const useStyles = makeStyles(theme => ({
  table: {
    // width: 900,
    margin: 'auto',
    '& td': {
      opacity: 1
    },
    '& thead th': {
      fontWeight: 400,
    },
    '& tbody th *': {
      fontWeight: 700,
    },
    '& tbody td': {
      fontSize: 18,
      fontWeight: 700,
    },
  },
  tableContainer: {
    padding: theme.spacing(3, 0),
  },
  titleWrapper: {
    padding: theme.spacing(3, 0),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  noticeWrapper: {
    '& .MuiAlert-root': {
      justifyContent: 'start',
    }
  },
  openMocWrapper: {
    paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    // paddingRight: theme.spacing(4),
  },
  separator: {
    backgroundColor: '#f6f6f6',
    marginLeft: '-32px',
    marginTop: '24px',
    width: 'calc(100% + 64px)',
    height: '24px'
  }
}));

const options = {
  maintainAspectRatio: false,
  cornerRadius: 10,
  fullCornerRadius: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          padding: 20,
        },
        gridLines: {
          drawTicks: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        offset: true,
        type: 'time',
        stacked: false,
        bounds: 'ticks',
        time: {
          unit: 'day',
          tooltipFormat: 'DD MMM',
          displayFormats: {
            day: 'DD. MMM yy',
          },
        },
        ticks: {
          padding: 20,
          beginAtZero: true,
          maxRotation: 0,
          maxTicksLimit: 15,
          callback: value => {
            const date = parse(value, 'dd. MMM y', new Date());
            const weekDay = format(date, 'EEE');
            const displayValue = format(date, 'dd MMM');

            return [weekDay, displayValue];
          },
        },
        gridLines: {
          borderDash: [8, 4],
          drawTicks: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      labelColor: (tooltipItem, chart) => {
        const dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
        return {
          backgroundColor: dataset.borderColor,
        };
      },
    },
  },
};

export const InsightsLeadCallVolumePanel = ({ data, onExpandToggle }) => {
  const classes = useStyles();
  const prismicContent = useStaticQuery(graphql`{
    allPrismicInsights {
      edges {
        node {
          data {
            title4a {
              html
              text
              raw
            }
            title4b {
              html
              text
              raw
            }
              tooltip4a {
                html
                text
                raw
              }
              tooltip4b {
                html
                text
                raw
              }
              message4a {
                html
                text
                raw
              }
              buttontext4 {
                html
                text
                raw
              }
              tooltip4c {
                html
                text
                raw
              }
              tooltip4d {
                html
                text
                raw
              }
              message4b {
                html
                text
                raw
              }
            }
          }
        }
      }
    }`
  );

  const prismicData = prismicContent.allPrismicInsights.edges[0].node.data;
  const { isExpanded, legends, dataSets, connectionsDataSets, pressTwoDataSets } = data;
  const lineChartData = coloredDatasets('line', { datasets: JSON.parse(JSON.stringify(dataSets)) }, 'normalDark');

  const [popup, setPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(null);

  const userData = useSelector(getMe);

  const tableColors = [
    ['rgba(133, 179, 200, 0.3)', 'rgba(133, 179, 200, 0.25)', 'rgba(133, 179, 200, 0.2)', 'rgba(133, 179, 200, 0.15)', 'rgba(133, 179, 200, 0.1)', 'rgba(133, 179, 200, 0.05)',],
    ['rgba(56, 183, 120, 0.3)', 'rgba(56, 183, 120, 0.25)', 'rgba(56, 183, 120, 0.2)', 'rgba(56, 183, 120, 0.15)', 'rgba(56, 183, 120, 0.1)', 'rgba(56, 183, 120, 0.05)',],
    ['rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0.3)',],
    ['rgba(238, 96, 85, 0.3)', 'rgba(238, 96, 85, 0.25)', 'rgba(238, 96, 85, 0.2)', 'rgba(238, 96, 85, 0.15)', 'rgba(238, 96, 85, 0.1)', 'rgba(238, 96, 85, 0.05)',]
  ];

  const handleExpand = () => {
    onExpandToggle();
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
    setPopup(!popup);
  };

  useEffect(() => {
    setPopup(false);
  }, []);

  useEffect(() => {
    const helpPage = pages.find((item) => {
      return item.name === 'Help and Support';
    });
    setPage(helpPage);
  }, [pages]);

  return (
    <Tab title={prismicData.title4a.text} expanded={isExpanded} onClick={handleExpand} drag>
      {lineChartData.datasets[0]?.data?.length > 0 ? (
        <>
          <ChartLegend legend={legends} maxWidth={600} colorType="normalDark" />

          <ChartWrapper>
            <Line options={options} data={lineChartData} height={300} />
          </ChartWrapper>

        </>
      ) : (
        <Typography align="center" variant="body1">
          There is no data for selected date range
        </Typography>
      )}

      <div className={classes.separator} ></div>

      <div className={classes.titleWrapper}>
        <Typography variant="h6">{prismicData.title4b.text}</Typography>
      </div>

      {connectionsDataSets?.columns?.length > 0 ? (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table aria-label="simple table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell />

                  {connectionsDataSets.columns.map(({ key, name }) => (
                    <TableCell key={key}>{name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {connectionsDataSets.data.map(({ rowName, rowData }, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2" align="left">
                        {rowName}
                      </Typography>
                    </TableCell>

                    {Object.entries(rowData).map(([key, value], idxItem) => (
                      <TableCell key={key} style={{ backgroundColor: tableColors[idx][idxItem] }}>
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell component="th" scope="row">
                    <TooltipedText
                      tooltipText={prismicData.tooltip4b.text}
                      rootStyle={{ flexDirection: 'row', alignItems: 'start', minWidth: '115px' }}
                      iconStyle={{ marginTop: '2px', marginLeft: 4 }}
                    >
                      <span variant="body2" align="left">
                        Calls Delivered
                      </span>
                    </TooltipedText>
                  </TableCell>
                  <TableCell>
                    {connectionsDataSets.total}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography align="center" variant="body1">
          There is no data for selected date range
        </Typography>
      )}

      <div style={{ marginTop: '16px', backgroundColor: '#F5ECB2', }} className={classes.noticeWrapper}>
        <Notice>
          Increase your connection rates by adjusting your reattempt schedule. See the <Link href="https://leverly.com/kb/reattempts-after-hour-leads/" target="_blank">highest connection data</Link> below for more details
          {/* <RichText key="message4a" render={prismicData.message4a.raw} /> */}
        </Notice>
        {
          page && (page.popupList.length > 0) && (userData?.customer?.is_subaccount === false) &&
          <>
            <div className={classes.buttonWrapper}>
              <Button variant="contained" color="primary" size="large" disableElevation
                onClick={(event) => {
                  setPopup(!popup);
                  setAnchorEl(event.currentTarget);
                }}>
                Get Help Now
              </Button>
            </div>
            <HelpWidget
              isOpen={popup}
              anchorEl={anchorEl}
              popupList={page.popupList}
              handleClosePopup={handleClosePopup}
            ></HelpWidget>
          </>
        }
      </div>

      <div className={classes.openMocWrapper}>
        <OpenMoc pageType="insights" />
      </div>

      <div className={classes.separator} ></div>

      <div className={classes.titleWrapper}>
        <Typography variant="h6">Calls Not Made</Typography>
      </div>

      {pressTwoDataSets?.columns?.length > 0 ? (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table aria-label="simple table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell />

                  {pressTwoDataSets.columns.map(({ key, name }) => (
                    <TableCell key={key}>{name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {pressTwoDataSets.data.map(({ rowName, rowData }, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body2" align="left">
                        {rowName}
                      </Typography>
                    </TableCell>

                    {Object.entries(rowData).map(([key, value], idxItem) => (
                      <TableCell key={key} style={{ backgroundColor: tableColors[3][idxItem] }} >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell component="th" scope="row">
                    <TooltipedText
                      tooltipText={prismicData.tooltip4b.text}
                      rootStyle={{ flexDirection: 'row', alignItems: 'start', minWidth: '115px' }}
                      iconStyle={{ marginTop: '2px', marginLeft: 3 }}
                    >
                      <span variant="body2" align="left">
                        Calls Delivered
                      </span>
                    </TooltipedText>
                  </TableCell>
                  <TableCell>
                    {connectionsDataSets.total}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography align="center" variant="body1">
          There is no data for selected date range
        </Typography>
      )}
      <div style={{ marginTop: '16px', backgroundColor: '#F5ECB2', }} className={classes.noticeWrapper}>
        <Notice>
          Look for a ways to increase your leads call rate. Look into <Link href="/me/group-performance">rep performance report</Link> to see the leads call rate by rep.
          {/* <RichText key="message4b" render={prismicData.message4b.raw} /> */}
        </Notice>

        {/* <div className={classes.openMocWrapper}>
          <OpenMoc pageType="insights" />
        </div> */}
      </div>
    </Tab>
  );
};
