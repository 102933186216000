/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { InsightsNewLeadFlowPanel } from './InsightsNewLeadFlowPanel';

import { getNewLeadFlow, getNewLeadFlowIsLoading } from '../../state/selectors';
import { useNewLeadFlowActions, useFetchNewLeadFlow } from '../../state/hooks';

export const InsightsNewLeadFlow = () => {
  const data = useSelector(getNewLeadFlow);
  const isLoading = useSelector(getNewLeadFlowIsLoading);
  const { toggleNewLeadFlowPanel } = useNewLeadFlowActions();

  useFetchNewLeadFlow();

  return (
    <LoadingContainer loading={isLoading}>
      <InsightsNewLeadFlowPanel data={data} onExpandToggle={toggleNewLeadFlowPanel} />
    </LoadingContainer>
  );
};
