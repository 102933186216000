/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React, { useRef, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Bar } from 'react-chartjs-2';
import { format, parse } from 'date-fns';
import { Typography } from '@material-ui/core';

import Tab from 'src/components/tab';
import ChartWrapper from 'src/components/chartWrapper';
import Notice from 'src/components/notice';
import RichText from 'src/components/richText';
import { coloredDatasets } from 'src/helpers/chartJsHelpers';
import { getWeekDayName } from 'src/helpers/time';
import Custom3DFlatChart from 'src/components/custom3DFlatChart';

const options = {
  maintainAspectRatio: false,
  cornerRadius: 10,
  fullCornerRadius: false,
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          padding: 20,
          // suggestedMax: 25,
        },
        gridLines: {
          drawTicks: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        offset: true,
        type: 'time',
        // distribution: 'series',
        stacked: false,
        bounds: 'ticks',
        time: {
          unit: 'day',
          tooltipFormat: 'D MMM hh:mm a',
          displayFormats: {
            day: 'DD. MMM yy',
          },
        },
        ticks: {
          // labelOffset: 30,
          // maxTicksLimit: 20,

          padding: 20,
          beginAtZero: true,
          maxRotation: 0,
          fontColor: '#202239',
          callback: value => {
            const date = parse(value, 'dd. MMM y', new Date());
            const weekDay = format(date, 'EEE');
            const displayValue = format(date, 'dd MMM');

            return [weekDay, displayValue];
          },
        },
        gridLines: {
          display: true,
        },
        // categoryPercentage: 0.7,
        barPercentage: 0.75,
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
  },
};

export const InsightsNewLeadFlowPanel = ({ data, onExpandToggle }) => {
  const { isExpanded, labels, dataSet, daysCout, hours, maxValue } = data;

  const chartRef = useRef(null);

  const prismicContent = useStaticQuery(graphql`
    {
      allPrismicInsights {
        edges {
          node {
            data {
              title5 {
                html
                text
                raw
              }
              titletooltip5 {
                html
                text
                raw
              }
              message5 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicInsights.edges[0].node.data;

  const [open, setOpen] = useState(true)
  useEffect(() => {
    setOpen(false);
    setTimeout(() => {
      setOpen(true);
    }, 200);

  }, [daysCout]);

  const handleExpand = () => {
    onExpandToggle();
  };

  return (
    <Tab
      title={prismicData.title5.text}
      tooltipedText={prismicData.titletooltip5.text}
      onClick={handleExpand}
      expanded={isExpanded}
      drag
    >
      {dataSet && (dataSet.length > 0) && open ? (
        <>
          <Custom3DFlatChart hours={hours} dataSet={dataSet} labels={labels} maxValue={maxValue} />

          <div style={{ marginTop: '16px' }}>
            <Notice>
              <RichText render={prismicData.message5.raw} />
            </Notice>
          </div>
        </>
      ) : (
        <Typography align="center" variant="body1">
          There is no data for selected date range
        </Typography>
      )}
    </Tab>
  );
};
