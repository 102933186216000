import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGroupId, getPage, getReportingDateRange, getGroups, getGroupsLoading } from './selectors';
import { actions } from './slice';
import { actions as groupActions } from 'src/features/groups/slice';

export const useReportingData = () => {
  const dispatch = useDispatch();
  const { from, to } = useSelector(getReportingDateRange);

  const setReportingDate = useCallback(dateRange => dispatch(actions.setReportingDate(dateRange)), [dispatch]);

  return {
    from,
    to,

    setReportingDate,
  };
};

// first attempt performance
export const useFirstAttemptPerformanceActions = () => {
  const dispatch = useDispatch();

  const toggleFirstAttemptPerformancePanel = useCallback(() => dispatch(actions.toggleFirstAttemptPerformancePanel()), [
    dispatch,
  ]);

  return {
    toggleFirstAttemptPerformancePanel,
  };
};

export const useFetchFirstAttemptPerformance = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);


  useEffect(() => {
    dispatch(actions.getFirstAttemptPerformance({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// connections by attempt
export const useConnectionsByAttemptActions = () => {
  const dispatch = useDispatch();

  const toggleConnectionsByAttemptPanel = useCallback(() => dispatch(actions.toggleConnectionsByAttemptPanel()), [
    dispatch,
  ]);

  return {
    toggleConnectionsByAttemptPanel,
  };
};

export const useFetchConnectionsByAttempt = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getConnectionsByAttempt({ date, groupId }));
  }, [dispatch, date, groupId]);
};

export const useGroupsData = () => {
  const data = useSelector(getGroups);

  return data;
};

export const useFetchGroups = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(groupActions.getGroups());
  }, [dispatch]);
};

// no connections
export const useNoConnectionsActions = () => {
  const dispatch = useDispatch();

  const toggleNoConnectionsPanel = useCallback(() => dispatch(actions.toggleNoConnectionsPanel()), [dispatch]);

  return {
    toggleNoConnectionsPanel,
  };
};

export const useFetchNoConnections = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getNoConnections({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// lead call volume
export const useLeadCallVolumeActions = () => {
  const dispatch = useDispatch();

  const toggleLeadCallVolumePanel = useCallback(() => dispatch(actions.toggleLeadCallVolumePanel()), [dispatch]);

  return {
    toggleLeadCallVolumePanel,
  };
};

export const useFetchLeadCallVolume = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getLeadCallVolume({ date, groupId }));
    dispatch(actions.getPressTwoConnectionsRate({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// new lead flow
export const useNewLeadFlowActions = () => {
  const dispatch = useDispatch();

  const toggleNewLeadFlowPanel = useCallback(() => dispatch(actions.toggleNewLeadFlowPanel()), [dispatch]);

  return {
    toggleNewLeadFlowPanel,
  };
};

export const useFetchNewLeadFlow = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getNewLeadFlow({ date, groupId }));
  }, [dispatch, date, groupId]);
};

// highest connections
export const useHighestConnectionsActions = () => {
  const dispatch = useDispatch();

  const toggleHighestConnectionsPanel = useCallback(() => dispatch(actions.toggleHighestConnectionsPanel()), [
    dispatch,
  ]);

  return {
    toggleHighestConnectionsPanel,
  };
};

export const useFetchHighestConnections = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(getPage);
  const groupId = useSelector(getGroupId);

  useEffect(() => {
    dispatch(actions.getHighestConnections({ date, groupId }));
  }, [dispatch, date, groupId]);
};
