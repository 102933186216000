/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { InsightsConnectionsByAttemptPanel } from './InsightsConnectionsByAttemptPanel';

import { useConnectionsByAttemptActions, useFetchConnectionsByAttempt, useGroupsData, useFetchGroups } from '../../state/hooks';
import { getConnectionsByAttempt, getConnectionsByAttemptIsLoading } from '../../state/selectors';

export const InsightsConnectionsByAttempt = () => {
  const data = useSelector(getConnectionsByAttempt);
  const isLoading = useSelector(getConnectionsByAttemptIsLoading);
  const { toggleConnectionsByAttemptPanel } = useConnectionsByAttemptActions();
  const groupData = useGroupsData();

  useFetchConnectionsByAttempt();
  useFetchGroups();

  return (
    <LoadingContainer loading={isLoading}>
      <InsightsConnectionsByAttemptPanel data={data} groupData={groupData} onExpandToggle={toggleConnectionsByAttemptPanel} />
    </LoadingContainer>
  );
};
