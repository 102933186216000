import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { format, parse } from 'date-fns';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0),
    display: 'flex',
  },
  container: {
    overflow: 'auto',
    paddingBottom: 12,
  },
  dayContainer: {
    // width: 110,
  },
  dayContainerBorders: {
    display: 'flex',
    flexDirection: 'column-reverse',
    borderLeft: '1px solid #EBEBEB',
    borderBottom: '1px solid #BBBCC3',
    paddingRight: 10,
  },
  hoursLegendContainer: {
    width: 60,
    paddingRight: 13,
    borderBottom: '1px solid #BBBCC3',
  },
  hoursLegendBorders: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  hoursLegend: {
    marginBottom: 5,
    height: 15,
    fontSize: 12,
    // textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
  },
  hoursLegendText: {
    width: '100%',
    textAlign: 'right',
  },
  hourContainer: {
    backgroundColor: '#FEF077',
    color: '#DAAF16',
    marginBottom: 5,
    height: 15,
    fontSize: 11,
    display: 'flex',
    alignItems: 'center',
    // '&:hover': {
    //   border: '1px solid #DAAF16',
    // }
  },
  hourText: {
    width: '100%',
    paddingRight: 4,
    textAlign: 'right',
  }
}));

export default function Custom3DFlatChart({ hours, dataSet, maxValue }) {
  const classes = useStyles();
  const [hoursList, setHoursList] = useState([])

  useEffect(() => {
    const list = [];
    for (let i = hours.minHour; i <= hours.maxHour; i++) {
      list.push(i);
    }
    setHoursList(list);
  }, []);

  const handleMouseEnter = (event) => {
    const yId = event.currentTarget.dataset.y;
    const hourLegendElement = document.querySelector(`[data-y="hour ${yId}"]`);
    hourLegendElement.style.fontWeight = 700;
    hourLegendElement.style.fontSize = '14px';
    const hourElements = document.querySelectorAll(`[data-y="${yId}"]`);
    hourElements.forEach(el => {
      if (el.style.width === '0%') {
        return;
      }
      el.style.boxShadow = '0 0 3px 1px #DAAF16';
    })
  }

  const handleMouseLeave = (event) => {
    const yId = event.currentTarget.dataset.y;
    const hourLegendElement = document.querySelector(`[data-y="hour ${yId}"]`);
    hourLegendElement.style.fontWeight = 400;
    hourLegendElement.style.fontSize = '12px';
    const hourElements = document.querySelectorAll(`[data-y="${yId}"]`);
    hourElements.forEach(el => {
      if (el.style.width === '0%') {
        return;
      }
      el.style.boxShadow = 'none';
    })
  }

  return (
    <div role="presentation" className={classes.root}>
      <div role="presentation" key="hours" style={{}}>
        <div role="presentation" className={classes.hoursLegendContainer}>
          <div role="presentation" className={classes.hoursLegendBorders}>
            {hoursList.map((hour, hourId) => (
              <span role="presentation" key={`hour ${hour}`} className={classes.hoursLegend} data-y={`hour ${hourId}`}>
                <span role="presentation" className={classes.hoursLegendText}>
                  {format(new Date().setHours(hour), 'h a')}
                </span>
              </span>
            ))}
          </div>
        </div>
      </div>
      <div role="presentation" style={{ display: 'flex', width: '100%' }} className={classes.container}>
        {dataSet.map((perDay, xId) => (
          <div role="presentation" key={`day ${perDay.label}`} style={{ minWidth: perDay.isEmpty ? 55 : 110, width: perDay.isEmpty ? '50%' : '100%' }}>
            <div role="presentation" className={classes.dayContainer}>
              <div role="presentation" className={classes.dayContainerBorders}>
                {perDay.data.map((perHour, yId) => (
                  <span role="presentation"
                    key={`hour ${perDay.label} ${perHour.label}`}
                    data-x={xId} data-y={yId}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className={classes.hourContainer}
                    // style={{ width: (Math.ceil(100 / maxValue * (perHour.value))) }}>
                    style={{ width: `${(Math.ceil(perHour.value / maxValue * 100))}%` }}>
                    <span role="presentation" className={classes.hourText}>{perHour.value > 0 ? `${perHour.value}` : ''}</span>
                  </span>
                ))}
              </div>
            </div>
            <span role="presentation">
              {format(new Date(perDay.label), 'EEE')}<br />
              {format(new Date(perDay.label), 'dd MMM')}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
