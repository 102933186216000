/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { makeStyles, Button, Typography } from '@material-ui/core';

import Tab from 'src/components/tab';
import ChartWrapper from 'src/components/chartWrapper';
import Notice from 'src/components/notice';
import RichText from 'src/components/richText';
import { coloredDatasets } from 'src/helpers/chartJsHelpers';

const useStyles = makeStyles(theme => ({
  table: {
    width: 750,
    margin: 'auto',
  },
  buttonWrapper: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  noticeWrapper: {
    '& .MuiAlert-root': {
      justifyContent: 'start',
    }
  }
}));

const options = {
  maintainAspectRatio: false,
  cornerRadius: 10,
  fullCornerRadius: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          padding: 20,
          callback: (volume) => {
            return volume + '%';
          }
        },
        gridLines: {
          drawTicks: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          padding: 20,
          fontColor: '#202239'
        },
        gridLines: {
          display: false,
        },
        categoryPercentage: 0.5,
        barPercentage: 0.4,
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        return `Rate: ${tooltipItem.yLabel}%`;
      },
    },
  },
};

export const InsightsConnectionsByAttemptPanel = ({ data, groupData, onExpandToggle }) => {
  const classes = useStyles();

  const { isExpanded, labels, dataSets } = data;

  const prismicContent = useStaticQuery(graphql`
    {
      allPrismicInsights {
        edges {
          node {
            data {
              title2 {
                html
                text
                raw
              }
              titletooltip2 {
                html
                text
                raw
              }
              message2 {
                html
                text
                raw
              }
              buttontext2 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicInsights.edges[0].node.data;

  const lineChartData = coloredDatasets('bar', { labels, datasets: JSON.parse(JSON.stringify(dataSets)) }, 'reverse');

  const handleExpand = () => {
    onExpandToggle();
  };

  const navigateToReattempt = () => {
    if (groupData.length > 1) {
      navigate('/me/settings/routing-rules?page=group');
    } else {
      navigate('/me/settings/routing-rules?page=group&key=call-attempt');
    }
  };

  return (
    <Tab
      title={prismicData.title2.text}
      tooltipedText={prismicData.titletooltip2.text}
      expanded={isExpanded}
      onClick={handleExpand}
      drag
    >
      {labels.length > 0 ? (
        <>
          <ChartWrapper>
            <Bar options={options} data={lineChartData} height={300} />
          </ChartWrapper>

          <div style={{ marginTop: '16px', backgroundColor: '#F5ECB2', }} className={classes.noticeWrapper}>

            <Notice>
              <RichText render={prismicData.message2.raw} />
            </Notice>

            <div className={classes.buttonWrapper}>
              <Button variant="contained" color="primary" size="large" disableElevation onClick={navigateToReattempt}>
                {(groupData && groupData.length > 1) ? 'Change Reattempt Settings' : prismicData.buttontext2.text}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <Typography align="center" variant="body1">
          There is no data for selected date range
        </Typography>
      )}
    </Tab>
  );
};
