/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { InsightsFirstAttemptPerformancePanel } from './InsightsFirstAttemptPerformancePanel';

import { getFirstAttemptPerformance, getFirstAttemptPerformanceIsLoading } from '../../state/selectors';
import { useFirstAttemptPerformanceActions, useFetchFirstAttemptPerformance } from '../../state/hooks';

export const InsightsFirstAttemptPerformance = () => {
  const data = useSelector(getFirstAttemptPerformance);
  const isLoading = useSelector(getFirstAttemptPerformanceIsLoading);
  const { toggleFirstAttemptPerformancePanel } = useFirstAttemptPerformanceActions();

  useFetchFirstAttemptPerformance();

  return (
    <LoadingContainer loading={isLoading}>
      <InsightsFirstAttemptPerformancePanel data={data} onExpandToggle={toggleFirstAttemptPerformancePanel} />
    </LoadingContainer>
  );
};
