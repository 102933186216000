/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import formatISO from 'date-fns/formatISO9075';
import addSeconds from 'date-fns/addSeconds';

import Paper from 'src/components/paper';
import TooltipedText from 'src/components/tooltipedText';
import DateRangePicker from 'src/components/dateRangePicker';

import { useReportingData } from '../../state/hooks';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  paperStyle: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.secondary.main,
      color: '#ffffff'
    }
  },
}));

export const InsightsReportingDateFilter = () => {
  const classes = useStyles();
  const { from, to, setReportingDate } = useReportingData();

  const handleDateRangeChange = ({ from, to }) => {
    setReportingDate({ from: formatISO(from), to: formatISO(to) });
  };

  return (
    <Paper className={classes.paperStyle}>
      <div className={classes.header}>
        <TooltipedText tooltipText="Applies to entire section below" tooltipStyle={{ backgroundColor: '#ffffff', color: '#202239' }}>
          <Typography variant="h6">Insights Reports Date Range</Typography>
        </TooltipedText>
      </div>
      <Grid container spacing={5}>
        <Grid item xs={3}>
          <div>
            <div style={{ marginTop: 4 }}>
              <DateRangePicker onChange={handleDateRangeChange} from={from} to={to} />
            </div>
          </div>
        </Grid>
      </Grid>

    </Paper>
  );
};
