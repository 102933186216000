/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { InsightsHighestConnectionsPanel } from './InsightsHighestConnectionsPanel';

import { getHighestConnections, getHighestConnectionsIsLoading } from '../../state/selectors';
import { useHighestConnectionsActions, useFetchHighestConnections } from '../../state/hooks';

export const InsightsHighestConnections = () => {
  const data = useSelector(getHighestConnections);
  const isLoading = useSelector(getHighestConnectionsIsLoading);
  const { toggleHighestConnectionsPanel } = useHighestConnectionsActions();

  useFetchHighestConnections();

  return (
    <LoadingContainer loading={isLoading}>
      <InsightsHighestConnectionsPanel data={data} onExpandToggle={toggleHighestConnectionsPanel} />
    </LoadingContainer>
  );
};
