/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { InsightsLeadCallVolumePanel } from './InsightsLeadCallVolumePanel';

import { getLeadCallVolume, getLeadCallVolumeIsLoading } from '../../state/selectors';
import { useLeadCallVolumeActions, useFetchLeadCallVolume } from '../../state/hooks';

export const InsightsLeadCallVolume = () => {
  const data = useSelector(getLeadCallVolume);
  const isLoading = useSelector(getLeadCallVolumeIsLoading);
  const { toggleLeadCallVolumePanel } = useLeadCallVolumeActions();

  useFetchLeadCallVolume();

  return (
    <LoadingContainer loading={isLoading}>
      <InsightsLeadCallVolumePanel data={data} onExpandToggle={toggleLeadCallVolumePanel} />
    </LoadingContainer>
  );
};
