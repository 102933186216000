import React from 'react';

import SEO from 'src/components/seo';
import GridLayout from 'src/components/draggableGrid';

import {
  InsightsReportingDateFilter,
  InsightsFirstAttemptPerformance,
  InsightsConnectionsByAttempt,
  InsightsNoConnections,
  InsightsLeadCallVolume,
  InsightsNewLeadFlow,
  InsightsHighestConnections,
} from 'src/features/insights';

export default function Onboarding() {
  return (
    <>
      <SEO title="Insights" />

      <InsightsReportingDateFilter />

      <GridLayout>
        <InsightsFirstAttemptPerformance />

        <InsightsConnectionsByAttempt />

        {/* <InsightsNoConnections /> */}

        <InsightsLeadCallVolume />

        <InsightsNewLeadFlow />

        <InsightsHighestConnections />
      </GridLayout>
    </>
  );
}
