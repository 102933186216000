/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Line } from 'react-chartjs-2';
import { format, parse } from 'date-fns';
import { makeStyles, Button, Typography } from '@material-ui/core';

import Tab from 'src/components/tab';
import ChartWrapper from 'src/components/chartWrapper';
import Notice from 'src/components/notice';
import HelpWidget from 'src/components/helpWidget';
import { coloredDatasets } from 'src/helpers/chartJsHelpers';
import { getWeekDayName } from 'src/helpers/time';

import pages from 'src/features/me/pages';

const useStyles = makeStyles(theme => ({
  table: {
    width: 750,
    margin: 'auto',
  },
  buttonWrapper: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  noticeWrapper: {
    '& .MuiAlert-root': {
      justifyContent: 'start',
    }
  }
}));

const options = {
  maintainAspectRatio: false,
  cornerRadius: 10,
  fullCornerRadius: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          padding: 20,
        },
        gridLines: {
          drawTicks: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        offset: true,
        type: 'time',
        stacked: false,
        bounds: 'ticks',
        time: {
          unit: 'day',
          tooltipFormat: 'DD MMM',
          displayFormats: {
            day: 'DD. MMM yy',
          },
        },
        ticks: {
          padding: 20,
          beginAtZero: true,
          maxTicksLimit: 15,
          maxRotation: 0,
          fontColor: '#202239',
          callback: value => {
            const date = parse(value, 'dd. MMM y', new Date());
            const weekDay = format(date, 'EEE');//getWeekDayName(date);
            const displayValue = format(date, 'dd MMM');

            return [weekDay, displayValue];
          },
        },
        gridLines: {
          borderDash: [8, 4],
          drawTicks: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      labelColor: (tooltipItem, chart) => {
        const dataset = chart.config.data.datasets[tooltipItem.datasetIndex];
        return {
          backgroundColor: dataset.borderColor,
        };
      },
    },
  },
};

export const InsightsNoConnectionsPanel = ({ data, onExpandToggle }) => {
  const classes = useStyles();

  const { isExpanded, dataSets } = data;

  const prismicContent = useStaticQuery(graphql`
    {
      allPrismicInsights {
        edges {
          node {
            data {
              title3 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicInsights.edges[0].node.data;

  const lineChartData = coloredDatasets('line', { datasets: JSON.parse(JSON.stringify(dataSets)) }, 'red');
  const handleExpand = () => {
    onExpandToggle();
  };

  const [popup, setPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(null);

  const handleClosePopup = () => {
    setAnchorEl(null);
    setPopup(!popup);
  };

  useEffect(() => {
    setPopup(false);
  }, []);

  useEffect(() => {
    const helpPage = pages.find((item) => {
      return item.name === 'Help and Support';
    });
    setPage(helpPage);
  }, [pages]);

  return (
    <Tab title={prismicData.title3.text} expanded={isExpanded} onClick={handleExpand} drag>
      {lineChartData.datasets[0]?.data.length > 0 ? (
        <>
          <ChartWrapper>
            <Line options={options} data={lineChartData} height={300} />
          </ChartWrapper>
          <div style={{ marginTop: '16px', backgroundColor: '#F5ECB2', }} className={classes.noticeWrapper}>
            <Notice>Revive leads with no connection by adding them to a Lead Revival campaign</Notice>

            {
              page && (page.popupList.length > 0) &&
              <>
                <div className={classes.buttonWrapper}>
                  <Button variant="contained" color="primary" size="large" disableElevation
                    onClick={(event) => {
                      setPopup(!popup);
                      setAnchorEl(event.currentTarget);
                    }}>
                    Get Help Now
                  </Button>
                </div>
                <HelpWidget
                  isOpen={popup}
                  anchorEl={anchorEl}
                  popupList={page.popupList}
                  handleClosePopup={handleClosePopup}
                ></HelpWidget>
              </>
            }
          </div>

        </>
      ) : (
        <Typography align="center" variant="body1">
          There is no data for selected date range
        </Typography>
      )}
    </Tab>
  );
};
