import React from 'react';
import { Alert } from '@material-ui/lab';
import { makeStyles, withStyles } from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';
import theme from 'src/themes/defaultTheme';

const StyledAlert = withStyles(() => ({
  root: {
    fontSize: 16,
    fontWeight: 700,
    // fontFamily: 'Lato',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      padding: theme.spacing(2),
    },
  },

  filledWarning: {
    backgroundColor: '#F5ECB2',
    color: theme.palette.text.primary,
  },
  message: {
    padding: 0,
  },
}))(Alert);

const useStyles = makeStyles(() => ({}));

export default function Notice({ children }) {
  const classes = useStyles();
  return (
    <StyledAlert variant="filled" severity="warning" icon={<InfoIcon />}>
      {children}
    </StyledAlert>
  );
}
