/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import 'chartjs-plugin-labels';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles, Button, Typography } from '@material-ui/core';

import Tab from 'src/components/tab';
import ChartWrapper from 'src/components/chartWrapper';
import TooltipedText from 'src/components/tooltipedText';
import Notice from 'src/components/notice';
import RichText from 'src/components/richText';
import HelpWidget from 'src/components/helpWidget';

import pages from 'src/features/me/pages';
import { getMe } from 'src/features/users/state/selectors';

const doughnutChartOption = {
  maintainAspectRatio: false,
  cutoutPercentage: 75,
  rotation: 0.5 * Math.PI,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  legend: {
    display: false,
  },
};

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    // [theme.breakpoints.up('lg')]: {
    //   position: 'absolute',
    // },
  },
  buttonWrapper: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  textWrapper: {
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  doughnutChartWrapper: {
    height: 160,
    width: 160,
    margin: theme.spacing(0, 3),
    position: 'relative',
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 0),
  },
  text: {
    marginTop: 25,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
  },
  textLabel: {
    fontWeight: 700,
  },
  tooltipText: {
    marginTop: theme.spacing(3),
  },
}));

export const InsightsFirstAttemptPerformancePanel = ({ data, onExpandToggle }) => {
  const classes = useStyles();

  const userData = useSelector(getMe);

  const { isExpanded, dataSets } = data;

  const prismicContent = useStaticQuery(graphql`
    {
      allPrismicInsights {
        edges {
          node {
            data {
              title1 {
                html
                text
                raw
              }
              titletooltip1 {
                html
                text
                raw
              }
              tooltip1a {
                html
                text
                raw
              }
              tooltip1b {
                html
                text
                raw
              }
              tooltip1c {
                html
                text
                raw
              }
              tooltip1d {
                html
                text
                raw
              }
              message1a {
                html
                text
                raw
              }
              message1b {
                html
                text
                raw
              }
              message1c {
                html
                text
                raw
              }
              message1d {
                html
                text
                raw
              }
              message1e {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);
  const prismicData = prismicContent.allPrismicInsights.edges[0].node.data;

  const labelTooltip = {
    total: prismicData.tooltip1a.text,
    pressTwo: prismicData.tooltip1b.text,
    connections: prismicData.tooltip1c.text,
    opportunities: prismicData.tooltip1d.text,
  };

  const doughnutChartData = JSON.parse(JSON.stringify(dataSets)).map(
    ({ key, name, value, percent, backgroundColor }) => ({
      key,
      name,
      tooltip: labelTooltip[key],
      data: { datasets: [{ data: percent, backgroundColor }] },
      value,
      percent,
    }),
  );

  const leadCallRate = doughnutChartData.filter((item) => {
    if (item.key !== 'pressTwo') {
      return false;
    }
    return true;
  })[0]?.percent[0];

  const connectionRate = doughnutChartData.filter((item) => {
    if (item.key !== 'connections') {
      return false;
    }
    return true;
  })[0]?.percent[0];


  const [popup, setPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(null);



  const handleExpand = () => {
    onExpandToggle();
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
    setPopup(!popup);
  };

  useEffect(() => {
    setPopup(false);
  }, []);

  useEffect(() => {
    const helpPage = pages.find((item) => {
      return item.name === 'Help and Support';
    });
    setPage(helpPage);
  }, [pages]);

  return (
    <Tab
      title={prismicData.title1.text}
      tooltipedText={prismicData.titletooltip1.text}
      onClick={handleExpand}
      expanded={isExpanded}
      drag
    >
      {doughnutChartData.length > 0 ? (
        <>
          <ChartWrapper>
            <div className={classes.chartContainer}>
              {doughnutChartData.map(({ key, data, value, percent, name, tooltip }) => (
                <div key={key}>
                  <div className={classes.doughnutChartWrapper}>
                    <Doughnut data={data} options={doughnutChartOption} />

                    <div className={classes.label}>
                      {key !== 'total' &&
                        <>
                          <Typography variant="h2">{parseFloat(percent[0].toFixed(2))}%</Typography>
                          <Typography variant="body1">
                            {value}
                          </Typography>
                        </>
                      }
                      {key === 'total' && <Typography variant="h2">{value}</Typography>}
                    </div>
                  </div>

                  <div className={classes.tooltipText}>
                    <TooltipedText position="center" tooltipText={tooltip}>
                      <Typography variant="body2" className={classes.textLabel} align="center">
                        {name}
                      </Typography>
                    </TooltipedText>
                  </div>
                </div>
              ))}
            </div>
          </ChartWrapper>
          {leadCallRate < 70 &&
            <div style={{ marginTop: '16px', backgroundColor: '#F5ECB2', }}>
              <Notice>
                <div>
                  {(leadCallRate >= 35) && (leadCallRate < 70) &&
                    <>Speed to lead is the number one driver of conversions.<br />
                      You may be leaking revenue because your leads called rate is less than optimum. We are happy to talk with you about how to increase your leads call rate.
                    </>
                  }
                  {(leadCallRate < 35) &&
                    <>
                      You may be leaking revenue because your connection rate is less than optimum. We are happy to talk with you about ways to increase your connection rate.
                    </>
                  }
                </div>
              </Notice>
              {
                (leadCallRate < 70) && page && (page.popupList.length > 0) && (userData?.customer?.is_subaccount === false) &&
                <>
                  <div className={classes.buttonWrapper}>
                    <Button variant="contained" color="primary" size="large" disableElevation
                      onClick={(event) => {
                        setPopup(!popup);
                        setAnchorEl(event.currentTarget);
                      }}>
                      Get Help Now
                    </Button>
                  </div>
                  <HelpWidget
                    isOpen={popup}
                    anchorEl={anchorEl}
                    popupList={page.popupList}
                    handleClosePopup={handleClosePopup}
                  ></HelpWidget>
                </>
              }
            </div>
          }
        </>
      ) : (
        <Typography align="center" variant="body1">
          There is no data for selected date range
        </Typography>
      )}
    </Tab>
  );
};
